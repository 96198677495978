<template>
  <v-scroll-y-reverse-transition mode="out-in">
    <router-view />
  </v-scroll-y-reverse-transition>
</template>

<script>
export default {
  name: 'App',
};
</script>
