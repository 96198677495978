import Vue from 'vue';
import VueWait from 'vue-wait';
import ErrorEvent from '@/models/ErrorEvent';
import VueHighlightJS from 'vue-highlightjs';
import PortalVue from 'portal-vue';
import Gravatar from 'vue-gravatar';
import VueCurrencyInput from 'vue-currency-input';
import VueCurrencyFilter from 'vue-currency-filter';
import App from './App.vue';
import './vee-validate';
import './registerServiceWorker';
import './plugins/base';
import plugins from './plugins/custom';
import filters from './filters';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';
import './styles/main.css';

Vue.use(filters);
Vue.use(plugins);
Vue.use(VueWait);
Vue.use(VueHighlightJS);
Vue.use(PortalVue);
Vue.use(VueCurrencyFilter,
  {
    symbol: 'R',
    thousandsSeparator: ',',
    fractionCount: 2,
    fractionSeparator: '.',
    symbolPosition: 'front',
    symbolSpacing: true,
  });
Vue.use(VueCurrencyInput, {
  globalOptions: {
    currency: {
      prefix: 'R ',
    },
    precision: 2,
    distractionFree: false,
    autoDecimalMode: true,
    valueRange: {
      min: 0,
    },
  },
});

Vue.component('VGravatar', Gravatar);

Vue.config.productionTip = false;

Vue.config.errorHandler = (err, vm, info) => {
  // err: error trace
  // vm: component in which error occured
  // info: Vue specific error information such as lifecycle hooks, events etc.

  const errorEvent = new ErrorEvent();
  errorEvent.sendError(
    err,
    'Unhandled',
    {
      componentId: vm.id,
      info,
    },
  );
};

window.onerror = function errhandle(msg, url, line, col, error) {
  const errorEvent = new ErrorEvent();
  errorEvent.sendError(
    error,
    'Unhandled',
    {
      msg,
      url,
      line,
      col,
      error,
    },
  );
};

window.addEventListener('unhandledrejection', (event) => {
  const errorEvent = new ErrorEvent();
  errorEvent.sendError(
    event.reason,
    'Unhandled',
    event.reason,
  );
});

Vue.mixin({
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    authUser: {
      get() {
        return this.$store.getters['session/user'];
      },
    },
    authUserCompany: {
      get() {
        return this.$store.getters['companies/company'];
      },
    },
  },
});

new Vue({
  router,
  store,
  vuetify,
  wait: new VueWait({
    useVuex: true,
    vuexModuleName: 'loading',
  }),
  render: (h) => h(App),
}).$mount('#app');
