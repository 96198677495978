<template>
  <ValidationProvider v-slot="{errors, classes}"
                      :name="label"
                      :rules="rules">
    <ess-input :label="label"
               :error="classes.invalid"
               :light="light">
      <v-select v-model="innerValue"
                :error="classes.invalid"
                :error-messages="errors"
                :color="light ? '#424242' : ''"
                :class="{'light': light}"
                :light="light"
                :menu-props="{'light': light}"
                outlined
                dense
                v-bind="$attrs"
                v-on="$listeners" />
    </ess-input>
  </ValidationProvider>
</template>

<script>
import inputBase from '@/mixins/inputBase';

export default {
  mixins: [inputBase],
};
</script>
