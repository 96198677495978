<template>
  <v-container>
    <v-row :class="{'mb-5': !dense}"
           justify="center">
      <v-col v-if="icon"
             xs12>
        <v-row justify="center">
          <v-icon :size="150"
                  :color="iconColor"
                  class="pt-3">
            {{ icon }}
          </v-icon>
        </v-row>
      </v-col>
      <v-col cols="12">
        <div class="ess-font-text-h1 text-center">
          {{ message }}
        </div>
        <div v-if="subMessage"
             class="ess-font-text-h4 text-center">
          {{ subMessage }}
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: '',
    },
    subMessage: {
      type: String,
      default: '',
    },
    dense: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: undefined,
    },
    iconColor: {
      type: String,
      default: 'grey lighten-5',
    },
  },
};
</script>
