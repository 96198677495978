import { GOALS, BENCHMARK_TYPES } from '@/config/constants';

export const DEFAULT_GOAL = {
  [GOALS.TYPES.WEIGHT]: {
    isMultiplied: false,
    type: GOALS.TYPES.WEIGHT,
    typeIcon: GOALS.TYPE_ICONS.WEIGHT,
    direction: GOALS.DIRECTIONS.UP,
    directionIcon: GOALS.DIRECTION_ICONS.UP,
    mask: '###',
    description: '100',
    suffix: 'kg',
    fieldType: null,
    goal: null,
    weeklyGoalPercentage: null,
    weeklyGoal: null,
  },
  [`${GOALS.TYPES.WEIGHT}_MULTIPLIED`]: {
    isMultiplied: false,
    type: GOALS.TYPES.WEIGHT,
    typeIcon: GOALS.TYPE_ICONS.WEIGHT,
    direction: GOALS.DIRECTIONS.UP,
    directionIcon: GOALS.DIRECTION_ICONS.UP,
    mask: '#.##',
    description: '1.50',
    suffix: 'kg',
    fieldType: null,
    goal: null,
    weeklyGoalPercentage: null,
    weeklyGoal: null,
  },
  [GOALS.TYPES.TIME]: {
    isMultiplied: false,
    type: GOALS.TYPES.TIME,
    typeIcon: GOALS.TYPE_ICONS.TIME,
    direction: GOALS.DIRECTIONS.DOWN,
    directionIcon: GOALS.DIRECTION_ICONS.DOWN,
    mask: 'time-with-seconds',
    description: '00:05:00',
    suffix: '',
    fieldType: null,
    goal: null,
    weeklyGoalPercentage: null,
    weeklyGoal: null,
  },
  [GOALS.TYPES.REPS]: {
    isMultiplied: false,
    type: GOALS.TYPES.REPS,
    typeIcon: GOALS.TYPE_ICONS.REPS,
    direction: GOALS.DIRECTIONS.UP,
    directionIcon: GOALS.DIRECTION_ICONS.UP,
    mask: '###',
    description: '30',
    suffix: 'reps',
    fieldType: null,
    goal: null,
    weeklyGoalPercentage: null,
    weeklyGoal: null,
  },
  [GOALS.TYPES.DISTANCE]: {
    isMultiplied: false,
    type: GOALS.TYPES.DISTANCE,
    typeIcon: GOALS.TYPE_ICONS.DISTANCE,
    direction: GOALS.DIRECTIONS.UP,
    directionIcon: GOALS.DIRECTION_ICONS.UP,
    mask: null,
    description: '1.50 or 1240',
    suffix: '',
    fieldType: 'number',
    goal: null,
    weeklyGoalPercentage: null,
    weeklyGoal: null,
  },
};

export const DEFAULT_BENCHMARKS = [
  {
    benchmarkName: 'Max Burpee 2min',
    type: BENCHMARK_TYPES.GYM,
  },
  {
    benchmarkName: 'Pull ups Weighted 3 RM',
    type: BENCHMARK_TYPES.GYM,
  },
  {
    benchmarkName: 'Abs roll outs max',
    type: BENCHMARK_TYPES.GYM,
  },
  {
    benchmarkName: 'Toes to Bar',
    type: BENCHMARK_TYPES.GYM,
  },
  {
    benchmarkName: 'KB Walks',
    type: BENCHMARK_TYPES.GYM,
  },
  {
    benchmarkName: '100 Kettle Bell Snatch',
    type: BENCHMARK_TYPES.GYM,
  },
  {
    benchmarkName: 'Bent Over Row 3RM',
    type: BENCHMARK_TYPES.GYM,
  },
  {
    benchmarkName: 'Bench Press 3 RM',
    type: BENCHMARK_TYPES.GYM,
  },
  {
    benchmarkName: 'Max Pull Up',
    type: BENCHMARK_TYPES.GYM,
  },
  {
    benchmarkName: 'Deadlift 3 RM',
    type: BENCHMARK_TYPES.GYM,
  },
  {
    benchmarkName: 'Millitary Press 3RM',
    type: BENCHMARK_TYPES.GYM,
  },
  {
    benchmarkName: 'Cleans 3 RM',
    type: BENCHMARK_TYPES.GYM,
  },
  {
    benchmarkName: 'Side plank 30sec',
    type: BENCHMARK_TYPES.GYM,
  },
  {
    benchmarkName: 'Plank 3min',
    type: BENCHMARK_TYPES.GYM,
  },
  {
    benchmarkName: 'Broad jump',
    type: BENCHMARK_TYPES.GYM,
  },
  {
    benchmarkName: 'Vertical jump',
    type: BENCHMARK_TYPES.GYM,
  },

  {
    benchmarkName: 'Wattbike 3min',
    type: BENCHMARK_TYPES.FITNESS,
  },
  {
    benchmarkName: '1km field',
    type: BENCHMARK_TYPES.FITNESS,
  },
  {
    benchmarkName: 'Row 20 x 1min',
    type: BENCHMARK_TYPES.FITNESS,
  },
  {
    benchmarkName: 'Row 3km',
    type: BENCHMARK_TYPES.FITNESS,
  },
  {
    benchmarkName: '1.6km field',
    type: BENCHMARK_TYPES.FITNESS,
  },
  {
    benchmarkName: 'YoYo Test',
    type: BENCHMARK_TYPES.FITNESS,
  },
  {
    benchmarkName: '3km field',
    type: BENCHMARK_TYPES.FITNESS,
  },
];

export const SPORTS_LIST = [
  { name: 'Other' },
  { name: 'Rugby' },
  { name: 'Soccer' },
  { name: 'Cricket' },
  { name: 'Crossfit' },
  { name: 'Functional Training' },
  { name: 'Road Cycling' },
  { name: 'Mountain Biking' },
  { name: 'Running' },
  { name: 'Trail Running' },
  { name: 'Spinning' },
  { name: 'Surfing' },
  { name: 'Rowing' },
  { name: 'Tennis' },
  { name: 'Baseball' },
  { name: 'Basketball' },
  { name: 'Bootcamp' },
  { name: 'Boxing' },
  { name: 'Dancing' },
  { name: 'Golf' },
  { name: 'Field Hocky' },
  { name: 'American Football' },
  { name: 'Gymnastics' },
  { name: 'Ice Skating' },
  { name: 'Inline Skating' },
  { name: 'Kickboxing' },
  { name: 'Ballet' },
];
