<template>
  <v-row dense>
    <v-col cols="12"
           v-if="label">
      <span :class="{'error--text': error, 'ess-font-title': !light, 'text-subtitle-1 font-weight-bold': light}">{{ label }}</span>
    </v-col>
    <v-col cols="12"
           class="py-0">
      <slot>
        <span class="text-body-1">{{ value }}</span>
      </slot>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number, Boolean],
      default: '',
    },
    noInput: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
