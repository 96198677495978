<template>
  <ValidationProvider v-slot="{errors, classes}"
                      :name="label"
                      :rules="rules">
    <ess-input :label="label"
               :error="classes.invalid"
               :light="light">
      <v-autocomplete v-model="innerValue"
                      :error="classes.invalid"
                      :error-messages="errors"
                      :color="light ? 'grey darken-3' : ''"
                      :class="{'light': light}"
                      outlined
                      single-line
                      dense
                      v-bind="$attrs"
                      v-on="$listeners"
                      @keydown="keyDown" />
    </ess-input>
  </ValidationProvider>
</template>

<script>
import inputBase from '@/mixins/inputBase';

export default {
  mixins: [inputBase],

  methods: {
    keyDown(event) {
      if (event.key === 'Enter') {
        this.$emit('enter');
      }
    },
  },
};
</script>
