import { USER_ROLES, BENCHMARK_TYPES } from './constants';

export default [
  {
    title: 'Dashboard',
    icon: 'mdi-view-dashboard',
    name: 'AthleteDashboard',
    role: USER_ROLES.ATHLETE,
  },
  {
    title: 'Home',
    icon: 'mdi-home',
    name: 'CoachDashboard',
    role: USER_ROLES.COACH,
  },
  {
    title: 'Gym',
    icon: 'mdi-dumbbell',
    name: 'AthleteScore',
    params: {
      benchmarkType: BENCHMARK_TYPES.GYM.toLowerCase(),
    },
    role: USER_ROLES.ATHLETE,
  },
  {
    title: 'Fitness',
    icon: 'mdi-timer',
    name: 'AthleteScore',
    params: {
      benchmarkType: BENCHMARK_TYPES.FITNESS.toLowerCase(),
    },
    role: USER_ROLES.ATHLETE,
  },
  {
    title: 'Administration',
    icon: 'mdi-tune',
    group: 'administration',
    role: USER_ROLES.ADMIN,
    children: [
      {
        name: 'AdminAdManagement',
        title: 'Ad Management',
        role: USER_ROLES.ADMIN,
      },
      {
        name: 'AdminUserManagement',
        title: 'User Management',
        role: USER_ROLES.ADMIN,
      },
    ],
  },
  {
    title: 'Athlete Management',
    icon: 'mdi-account-edit',
    group: 'coach',
    role: USER_ROLES.COACH,
    children: [
      {
        name: 'CoachAthletes',
        title: 'Athletes',
        role: USER_ROLES.COACH,
      },
      {
        name: 'CoachGroups',
        title: 'Groups',
        role: USER_ROLES.COACH,
      },
    ],
  },
  {
    title: 'RPE',
    icon: 'mdi-fire',
    group: 'rpe',
    role: USER_ROLES.COACH,
    children: [
      {
        name: 'CoachSessions',
        title: 'Sessions',
        role: USER_ROLES.COACH },
      {
        name: 'CoachReports',
        title: 'Reports',
        role: USER_ROLES.COACH },
    ],
  },
  {
    title: 'Gym/Fitness',
    icon: 'mdi-dumbbell',
    group: 'gymFitness',
    role: USER_ROLES.COACH,
    children: [
      {
        name: 'CoachBenchmarks',
        title: 'Goals',
        role: USER_ROLES.COACH },
      {
        name: 'CoachScores',
        title: 'Scores',
        role: USER_ROLES.COACH },
    ],
  },
  {
    title: 'Messages',
    icon: 'mdi-forum-outline',
    name: 'MainMessages',
  },
  {
    title: 'My Workouts',
    icon: 'mdi-run',
    name: 'AthleteWorkouts',
    role: USER_ROLES.ATHLETE,
  },
  {
    title: 'Help',
    icon: 'mdi-help',
    name: 'MainHelp',
  },
];
