<template>
  <div v-if="title"
       class="ess-font-text-h1 my-5">
    {{ title | titleCase }}
  </div>
</template>

<script>
export default {
  components: {},

  props: {
    title: {
      type: String,
      default: undefined,
    },
  },
};
</script>
