<template>
  <v-container class="ess-max">
    <ess-title v-if="title"
               :title="title" />

    <slot />
  </v-container>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: undefined,
    },
  },
};
</script>

<style scoped>
  .page-margin {
    margin-top: 30px;
  }
</style>
