export const SESSION_TYPES = {
  UNITS: 'UNITS',
  GYM: 'GYM',
  MATCH: 'MATCH',
  FIELD: 'FIELD',
  CONDITIONING: 'CONDITIONING',
};

export const SESSION_MEASUREMENTS = {
  COUNT: 'COUNT',
  DURATION: 'DURATION',
};

export const USER_ROLES = {
  COACH: 'Coach',
  ATHLETE: 'Athlete',
  ADMIN: 'Admin',
};

export const BENCHMARK_TYPES = {
  GYM: 'GYM',
  FITNESS: 'FITNESS',
};

export const GOALS = {
  TYPES: {
    WEIGHT: 'WEIGHT',
    TIME: 'TIME',
    REPS: 'REPS',
    DISTANCE: 'DISTANCE',
  },
  TYPE_ICONS: {
    WEIGHT: 'mdi-weight-kilogram',
    TIME: 'mdi-timer',
    REPS: 'mdi-all-inclusive',
    DISTANCE: 'mdi-ruler',
  },
  DIRECTIONS: {
    UP: 'UP',
    DOWN: 'DOWN',
  },
  DIRECTION_ICONS: {
    UP: 'mdi-trending-up',
    DOWN: 'mdi-trending-down',
  },
};

export const PROFILE_STATUS = {
  MISSING: 'MISSING',
  ACTIVE: 'ACTIVE',
};

export const LAYOUT_COLS = {
  0: 12,
  1: 4,
};

export const EMAIL_TEMPLATES = {
  ACTIVATE_ACCOUNT: 'activate-account',
};
