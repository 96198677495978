<template>
  <v-hover v-slot="{hover}">
    <v-card :elevation="flat ? null : hover ? 16 : 6"
            :class="{'on-hover': hover, 'rounded': !tile}"
            :color="color"
            :tile="tile"
            :flat="flat"
            v-bind="$attrs"
            v-on="$listeners">
      <slot name="title">
        <v-card-title v-if="title">
          <span class="text-truncate">
            {{ title }}
          </span>
        </v-card-title>
      </slot>

      <v-card-text v-if="loading"
                   class="fill-height ma-0"
                   align="center"
                   justify="center">
        <ess-loader />
      </v-card-text>

      <slot v-else />

      <div v-if="!noAction"
           :class="{'pl-3 pb-3 pr-3': !noActionPadding}">
        <v-card-actions>
          <slot name="actions" />
        </v-card-actions>
      </div>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    subtitle: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: undefined,
    },
    color: {
      type: String,
      default: '#263238',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    noActionPadding: {
      type: Boolean,
      default: false,
    },
    noAction: {
      type: Boolean,
      default: false,
    },
    tile: {
      type: Boolean,
      value: false,
    },
    flat: {
      type: Boolean,
      value: false,
    },
  },
};
</script>

<style scoped>
  .v-card--link:focus:before,
  .rounded {
    border-radius: 10px !important;
  }
</style>
