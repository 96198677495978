<template>
  <v-slide-x-transition appear
                        mode="out-in">
    <v-btn color="secondary"
           :large="!isMobile"
           @click="$router.push({name: pathName})">
      <v-icon :left="!isMobile">
        mdi-arrow-left-bold
      </v-icon>
      <span v-if="!isMobile">Back</span>
    </v-btn>
  </v-slide-x-transition>
</template>

<script>
export default {
  props: {
    pathName: {
      type: String,
      default: '',
    },
  },
};
</script>
