<template>
  <v-menu :ref="refName"
          v-model="displayPicker"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="innerValue"
          :disabled="disabled"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px">
    <template #activator="{on}">
      <ess-text-field v-model="innerValue"
                      readonly
                      v-bind="$attrs"
                      v-on="on" />
    </template>
    <v-date-picker v-if="displayPicker"
                   v-model="innerValue"
                   :max="max"
                   :min="min"
                   :color="color"
                   :light="light"
                   full-width
                   @change="$refs[refName].save(innerValue)" />
  </v-menu>
</template>

<script>
import pickerBaseMixin from '@/mixins/pickerBase';

export default {
  mixins: [pickerBaseMixin],
};
</script>
