import Vue from 'vue';
import _startCase from 'lodash/startCase';
import _capitalize from 'lodash/capitalize';

Vue.filter('startCase', (value) => _capitalize(_startCase(value)));
Vue.filter('floatNumber', (value) => value.toFixed(2));
Vue.filter('titleCase', (value) => value.replace(
  /\w\S*/g,
  (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
));

export default {
  Vue,
};
