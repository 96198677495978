<template>
  <ValidationProvider v-slot="{errors, classes}"
                      :name="label"
                      :rules="rules">
    <ess-input :label="label"
               :error="classes.invalid">
      <v-textarea v-model="innerValue"
                  :error="classes.invalid"
                  :error-messages="errors"
                  outlined
                  dense
                  v-bind="$attrs"
                  v-on="$listeners" />
    </ess-input>
  </ValidationProvider>
</template>

<script>
import inputBase from '@/mixins/inputBase';

export default {
  mixins: [inputBase],
};
</script>
